@mixin font-face($font-family, $file-name, $type, $weight, $style) {
  $baseDir: '../../fonts';

  @font-face {
    font-family: $font-family;
    src: url('#{$baseDir}/#{$file-name}/#{$file-name}-#{$type}.ttf')
      format('truetype');
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
  }
}

%icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important; // stylelint-disable-line
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Responsive pars
@mixin desktopMegaLarge() {
  @media screen and (min-width: $screen-xxxxl-min) {
    @content;
  }
}

@mixin desktopSuperLarge() {
  @media screen and (min-width: $screen-xxxl-min) {
    @content;
  }
}

@mixin desktopLarge() {
  @media screen and (min-width: $screen-xxl-min) {
    @content;
  }
}

@mixin maxDesktop() {
  @media not all and (min-width: $screen-xl-min) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: $screen-xl-min) {
    @content;
  }
}

@mixin smallDesktop() {
  @media (min-width: $screen-xl-min) and (max-width: $screen-xxl-min) {
    @content;
  }
}

@mixin desktopTablet() {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
    @content;
  }
}

@mixin minTablet() {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin tabletLandscape() {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    @content;
  }
}

@mixin tabletPortrait() {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin mobileTabletPortrait() {
  @media screen and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin mobile() {
  @media (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin smallMobile() {
  @media (max-width: $screen-xsm-max) {
    @content;
  }
}

// Custom mixin
@mixin customScrollBar() {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey-dark;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white-opacity;
    border-radius: 4px;
    border: 1px solid $grey-dark;
  }
}
