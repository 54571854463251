@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.wrapper {
  position: relative;

  .container {
    padding: 0 10px;

    .searchInputGroup {
      height: auto;
    }
  }
}

.rightLinks {
  display: none;
}

@include maxDesktop() {
  .wrapper .container .menu {
    display: none;
  }
}

@include minTablet() {
  .wrapper .container {
    padding: 0 20px;
  }
}

@include desktop() {
  .wrapper {
    .container {
      display: flex;
      margin: 0 auto;
      max-width: $page-max-width;

      .menu {
        flex-grow: 1;
        margin-right: 40px;
      }

      .menu, .searchInputGroup {
        min-height: 45px;
      }

      .rightSide {
        align-items: center;
        box-sizing: border-box;
        display: none;
        justify-content: right;
        max-width: 368px;
        width: 100%;

        .searchBar {
          height: 100%;
          max-width: $page-max-width;
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          z-index: 1;
          margin: 0 auto;

          transition-property: right, width;
          transition-duration: 0.2s;

          & > form {
            height: 100%;
          }
        }
      }
    }

    &.sticky {
      .container {
        padding-left: 0;

        .menu, .searchInputGroup {
          min-height: 55px;
        }

        .menu .menuItem > a > span::after {
          bottom: -9px;
        }
      }

      .rightSide {
        display: flex;
      }

      .rightLinks {
        display: flex;
        flex-wrap: nowrap;
      }
    }

    &.sticky {
      &:not(.searchOpen) .container .rightSide .searchBar {
        visibility: hidden;
      }

      .myAccountLink span, .cartLink span {
        display: none;
      }
    }
  }
}
