@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.navContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  column-gap: 43px;

  .searchBtn {
    background: none;
    border: none;
    cursor: pointer;
  }

  .navLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    height: 30px;
    position: relative;

    &.directOrderLink {
      flex: 0;
      flex-wrap: nowrap;
      flex-direction: row;
      margin-left: 0;
      gap: 10px;

      span {
        display: block;
        font-size: 12px;
        line-height: 16px;
        width: 80px;
        white-space: initial;
        position: initial;
        text-align: left;
      }

      &::after {
        display: none;
        content: "+";
        color: white;
        font-weight: 500;
        position: absolute;
        text-align: center;
        top: 10px;
        right: 0;
        width: 19px;
        height: 19px;
        background: #e5007e;
        border-radius: 100%;
        font-size: 18px;
        justify-content: center;
        align-items: center;
      }
    }

    span {
      font: normal normal 500 10px/12px $font-lato;
      color: #7A7A7A;
      white-space: nowrap;
      position: absolute;
      top: 40px;
      display: none
    }

    &:hover {
      span {
        display: inline;
      }
    }
  }

  .myAccountLoggedContainer .navLink {
    height: 45px;
  }

  &.sticky {
    justify-content: right;
    max-width: 368px;
    width: 100%;

    span {
      display: none !important;
    }

    .myAccountLoggedContainer .navLink {
      height: 50px;
    }
  }
}

@include tablet {
  .navContainer {
    column-gap: 36px;

    .navLink {
      height: initial;
      &.directOrderLink {
        padding-right: calc(19px / 2); // Badge size divided by two
        &::after {
          display: flex;
        }
      }

      img {
        height: 33px;
        width: auto;
      }
    }

    span {
      display: none !important;
    }
  }
}

@include mobile {
  .navContainer {
    column-gap: 15px;
    .navLink {
      height: initial;
      img {
        height: 22px;
        width: auto;
      }

      &.directOrderLink, &.helpLink {
        display: none;
      }
    }

    span {
      display: none !important;
    }
  }
}

