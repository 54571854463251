.button {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 27px;
  padding: 0;
}

.icon {
  color: #7a7a7a;
  vertical-align: top;
}
