@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.headerContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1800px;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;

  .leftBlock, .rightBlock {
    width: auto;
    flex: 1;
  }

  .leftBlock {
    display: flex;
    align-items: center;

    .openMenuBtn {
      cursor: pointer;
      padding: 0;
      font-size: 30px;
      color: $grey-3;
    }

    .searchBtn {
      font-size: 22px;
    }
  }

  .rightBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .closeMenuBtn {
    display: flex;
    background-color: transparent;
    border: none;

    svg {
      font-size: 22px;
      color: #707070;
    }
  }

  .searchBarContainer {
    width: 100%;
    flex: 1 0 100%;
    margin-top: 10px;
  }
}

@include minTablet() {
  .header {
    padding: 10px 20px 15px;

    .leftBlock, .rightBlock {
      flex-grow: 0;
    }
  }
}

@include desktop() {
  .header {
    flex-wrap: nowrap;
    height: 100px;
    padding: 12px 20px;

    &.sticky, .leftBlock {
      display: none;
    }

    .logo {
      order: -1;
    }

    .rightBlock {
      flex-grow: 0;
      order: 2;
    }

    .searchBarContainer {
      display: block;
      max-width: 500px;
      width: 100%;
      order: 1;
      margin-top: 0;
    }
  }
}
