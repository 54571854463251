@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.button {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  font-family: $font-lato;
  font-weight: bold;
  justify-content: center;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;

  &::first-letter {
    text-transform: capitalize;
  }
}

.primary {
  background-color: $pink;
  color: $pure-white;
  font-size: 0.938rem;
  padding: 15px 10px;
  text-transform: uppercase;

  &:active {
    background-color: $dark;
  }

  &:disabled {
    background-color: $grey-light-4;
    cursor: auto;
  }
}

.secondary {
  background-color: $pure-white;
  border: 1px solid $pink;
  color: $pink;
  font-size: 0.938rem;
  padding: 15px 10px;

  &:active {
    border-color: $dark;
    color: $dark;
  }

  &:disabled {
    border-color: $grey-light-4;
    color: $grey-light-4;
    cursor: auto;
  }
}

.tertiary {
  background-color: $pure-black;
  color: $pure-white;
  font-size: 0.75rem;
  padding: 10px 10px;

  &:disabled {
    background-color: $grey-light-4;
  }
}

.other {
  background-color: $pure-white;
  border: 1px solid $pure-black;
  color: $pure-black;
  font-size: 0.75rem;
  padding: 10px 10px;

  &:disabled {
    border-color: $grey-light-4;
    color: $grey-light-4;
  }
}

.fullWidth {
  width: 100%;
}

@media (hover: hover) {
  .primary:hover:not(:disabled) {
    background-color: $pure-black;
  }

  .secondary:hover:not(:disabled) {
    border-color: $pure-black;
    color: $pure-black;
  }
}

@include minTablet() {
  .button .primary,
  .button .secondary {
    padding: 15px 10px;
  }

  .button .tertiary,
  .button .other {
    padding: 12px 10px
  }
}
