@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.header .headerContainer {
  position: relative;
  background: #fff;
  transition: all .30s ease;
}

.headerContainer.sticky {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 20;
  box-shadow: 0 3px 6px #0000004D;
}

@include minTablet {
  .header .headerContainer {
    background: #ffffff;
  }
}

@include desktop {
  .header .headerContainer {
    padding-bottom: 15px;
    &.sticky {
      padding-bottom: 0;
    }
  }
}
